
































































































import { defineComponent, ref } from '@vue/composition-api'
import { mapActions, mapState } from 'vuex'
import TopBar from '@/components/organisms/o-top-bar.vue'
import MFilterList from '@/components/molecules/m-filter-list.vue'
import { QueryParams } from '@/store/api-plateform-utils'
import {
  usePagination,
  PaginationOptions,
  parseQuery,
} from '@/composables/pagination'
import Pagination from '@/components/base/Pagination.vue'
import router from '@/router'
import { userConnectedCanAccess } from '@/composables/UserGrant'
import { CarModel } from '@/api/interfaces/carmodel'

export default defineComponent({
  setup (props, ctx) {
    const canAddCarModel = () => userConnectedCanAccess('CarModel Create')
    const query = {
      ...router.app.$route.query,
    }
    const totalItems = () => {
      return ctx.root.$store.state.carModelList.totalItems
    }
    const filters = ref({
      model: query.model || '',
      brand: query.brand || '',
    })
    delete query.model
    delete query.brand
    const {
      paginationOptions,
      paginationRequestParams,
      nbPages,
      onSortBy,
      onSortDesc,
      }: PaginationOptions = usePagination(
    totalItems,
    parseQuery(query),
  ) as PaginationOptions

    return {
      paginationOptions,
      paginationRequestParams,
      nbPages,
      onSortBy,
      onSortDesc,
      filters,
      canAddCarModel,
    }
  },
  name: 'CarModelList',
  components: {
    MFilterList,
    TopBar,
    ListTextFilter: () => import('@/components/base/ListTextFilter.vue'),
    ListSelectFilter: () => import('@/components/base/ListSelectFilter.vue'),
    Pagination,
  },
  data () {
    return {
      title: this.$t('car_model.list.title_header'),
      headers: [
        {
          text: this.$t('car_model.list.headers.model'),
          value: 'model',
        },
        {
          text: this.$t('car_model.list.headers.brand'),
          value: 'carBrand.brand',
        },
        {
          text: this.$t('form.actions'),
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapState('carModelList', {
      carModelList: 'list',
      loading: 'loading',
    }),
    ...mapState('carBrand', {
      carBrandList: 'list',
    }),
    ...mapState('filterList', ['filter']),
    baseRequest (): QueryParams {
      const request: Record<string, unknown> = {
        model: this.filters.model,
        'carBrand.brand': this.filters.brand,
        ...this.paginationRequestParams,
      }
      return request as QueryParams
    },
  },
  created () {
    this.load(this.baseRequest)
    this.loadCarBrands()
  },
  watch: {
    filters: {
      handler () {
        this.paginationOptions.page = 1
        this.loadData()
      },
      deep: true,
    },
    paginationOptions: {
      handler () {
        this.loadData()
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('carModelList', {
      load: 'load',
    }),
    ...mapActions('carBrand', {
      loadCarBrands: 'load',
    }),
    loadData () {
      this.addParamsToLocation()
      this.load(this.baseRequest)
    },
    addParamsToLocation () {
      let query: any = {
        ...this.filters,
        ...this.paginationOptions,
      }
      query = Object.fromEntries(
        Object.entries(query).filter(([key, value]) => !!value),
      )
      router.replace({ name: 'CarModel List', query: query })
    },
    goToCarModelForm (item: CarModel) {
      if (item.id) {
        this.$router.push({
          name: 'CarModel Edit',
          params: { idCarModel: item.id.toString() },
        })
      }
    },
    filterByModel (value: string) {
      this.filters.model = value
    },
    filterByBrand (value: string) {
      this.filters.brand = value
    },
  },
  beforeRouteLeave (to, from, next) {
    if (to.name === 'CarModel Edit' || to.name === 'CarModel Create') {
      this.$store.commit('navigation/resetPile')
      this.$store.commit('navigation/addRoute', from)
    }
    next()
  },
})
